import React, {useEffect, useState} from 'react';
import mixinWrapper from '../hoc/mixinWrapper';
import { Link, useParams } from 'react-router-dom';
// import feature1 from '../assets/img/sdk/pdt_feature_01.png';
// import feature2 from '../assets/img/sdk/pdt_feature_02.png';
// import feature3 from '../assets/img/sdk/pdt_feature_03.png';
import {getFeatureList} from '../api';
import {useDispatch} from 'react-redux';
import MetaTags from 'react-meta-tags';

const SdkPage = props => {
    const { lang } = useParams();
    const {t,currentLang,linkObj, i18n} = props;
    const dispatch = useDispatch();
    const [featureAry, setFeatureAry] = useState([]);
    const featureTypeId = 'ba6783f8-c3f2-42ad-857b-4458fa970659';


    useEffect(() => {
        const fetchResource = cLang => {
            let lang = 'EN';
            if(cLang === 'zh-TW'){
                lang = 'CT';
            }else if(cLang === 'zh-CN'){
                lang = 'CS';
            }else{
                lang = cLang.toUpperCase();
            }
            getFeatureList({LgeID:lang, TypeID:featureTypeId, PageIndex:0, PageSize:100}).then(function (response) {
                dispatch({
                    type:'SEND_FEATURELIST',
                    featureListAry:response.data.List
                })
                setFeatureAry(response.data.List);
                // renderSuiteList(response.data.List);
            })
            .catch(function (error) {
                console.log('err',error);
            });
        
        }

        fetchResource(currentLang.type);
    }, [currentLang.type,dispatch]);

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);

    const renderIsTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "1"){
		return data.IsTop === "1" ? 
            //if((i % 2) ===0){
			((i % 2) ===0 ? 
                (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            {/* <h3 className="font-weight-bold b-text mb-3">{data.Name}</h3> */}
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {/* <Link to={`/${props.currentLang.type}/sdk/readmore/${data.ID}`} className="mt-20 b1Btn">
                                {t('learnMore')}
                            </Link> */}
                        </div>
                    </div>

                ) : 
            //} else {
                (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-33 featureInfo alignRight align-middle">
                            {/* <h3 className="font-weight-bold b-text mb-3">{data.Name}</h3> */}
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {/* <Link to={`/${props.currentLang.type}/sdk/readmore/${data.ID}`} className="mt-20 b1Btn">
                                {t('learnMore')}
                            </Link> */}
                        </div>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                    </div>

                ))
            //}
			: null;
        //}
    });
    
    const renderIsNotTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "0"){
            return data.IsTop === "0" ? (
                <div className="d-50 mb-120" key={i}>
                    <img src={data.Img_pc} className="img-fluid isNotTop-img" alt="" />
                    <div className="featureInfo paddL35 align-middle">
                        <h3 className="font-weight-bold m-text">{data.Name}</h3>
                        <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                        {data.IsMore===1?<Link to={`/${props.currentLang.type}/sdk/readmore/${data.ID}`} className="mt-20 b1Btn">
                            {t('learnMore')}
                        </Link>:''}
                    </div>
                </div>
                
            ) : null;
        //}
    });
    

    return (
        <section id="sdk" className="productInfoContent">
            <MetaTags>
                <title>{t('metaTag.sdk.title')}</title>
                <meta name="title" content={t('metaTag.sdk.title')} />
                <meta name="description" content={t('metaTag.sdk.desc')} />
                <meta name="keywords" content={t('metaTag.sdk.keywords')} />
                <meta property="og:title" content={t('metaTag.sdk.title')} />
                <meta property="og:description" content={t('metaTag.sdk.desc')} />
            </MetaTags>
            <div className="topBlock contentBlock text-white">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                             <h1 className="firstTitle">{t('sdk.name')}</h1>
                              <p className="secondTitle">
                                  {t('sdk.info1')}<br />
                                  {t('sdk.info2')}
                              </p>
                              <div className="btnGroup">
                                <p>{t('sdk.buyInfo')}</p>
                                <Link to={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`} className="cBtn w_text greenBg text-center">
                                    <div className="m-0 font-light mt-3">{t('contact.name')}</div>
                                </Link>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock whiteBg">
                <div className="customContainer">
                    <div className="cRow maxW1300 mtb-120">
                        <div className="d-100">
                            <h2 className="mb-title font-weight-bold mb-4">{t('sdk.featuresTitle')}</h2>
                            <p className="m-title m-0">{t('sdk.featuresInfo.t1')}</p>
                            <p className="m-title m-0">{t('sdk.featuresInfo.t2')}</p>
                        </div>
                    </div>
                    {/* <div className="cRow maxW1300 padding-0 column-reverse">
                        <div className="d-33 featureInfo alignRight align-middle">
                            <p>文電通PDF SDK提供完整的C++ API、範例和文件，可用以開發各種客製化的應用程式服務，其功能從PDF建立、轉換、解析、瀏覽至搜尋、註解、保護、列印、編輯PDF文件等，應有盡有。</p>
                        </div>
                        <div className="d-65 align-middle maxd819">
                            <img src={feature1}  className="img-fluid" alt="feature_01"/>
                        </div>
                    </div>
                    <div className="cRow maxW1300 padding-0">
                        <div className="d-65 align-middle maxd819">
                            <img src={feature2}  className="img-fluid" alt="feature_02"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            <p>除此之外，我們還提供了三個範例介紹如何在Windows系統上開發單執行緒(single-threaded)應用程式。</p>
                        </div>
                    </div>
                    <div className="cRow maxW1300 padding-0 column-reverse">
                        <div className="d-33 featureInfo alignRight align-middle">
                            <p>文電通PDF SDK同時也是用來構建文電通PDF系列產品的核心技術。</p>
                        </div>
                        <div className="d-65 align-middle maxd819">
                            <img src={feature3}  className="img-fluid" alt="feature_03"/>
                        </div>
                    </div> */}
                    {renderIsTopFeatureView}
                    {/* feature detail */}
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        {renderIsNotTopFeatureView}
                    </div>
                    <div className="cRow maxW1300 grayBg-gallery">
                        <div className="d-100 featureDetailInfo">
                            <h2 className="d-inline-block align-top" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.systemRequired.mainTitle')}
                            </h2>
                            <ul className="featureDetailList align-top">
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title1')}</p>
                                    <p>{t('converter.systemRequired.info1')}</p>
                                    <p style={{color:"#DC3C00"}}>{t('sdk.systemRequired.noteInfo')}</p>
                                </li>
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title2')}</p>
                                    <p>{t('suite.systemRequired.info2')}</p>
                                    <p>{t('suite.systemRequired.info3')}</p>
                                    <p>{t('suite.systemRequired.info4')}</p>
                                </li>
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title3')}</p>
                                    <p>{t('suite.systemRequired.info5')}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="d-100 featureDetailInfo">
                            <h2 className="d-inline-block" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.resourceTitle')}
                            </h2>
                            <ul className="featureDetailList boxList align-top">
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.tutorials.userManuals.path}`}>
                                        <span className="font-light">{t('tutorials.userManuals')}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature bottom */}
            <div className="bottomBlock contentBlock text-white">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                            <div className="firstTitle">{t('sdk.bottomInfo.t1')}</div>
                            <p className="secondTitle">
                                {t('sdk.bottomInfo.t2')}
                            </p>
                            <div className="btnGroup">
                                <Link to={`/${props.currentLang.type}${linkObj.contact.contactSales.path}`} className="cBtn w_text greenBg text-center">
                                    <div className="m-0 font-light mt-3">{t('fillForm')}</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default mixinWrapper(SdkPage);