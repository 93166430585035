import React, {useEffect, useState} from 'react';
import mixinWrapper from '../hoc/mixinWrapper';
import { Link, useParams } from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import feature1 from '../assets/img/readerMobile/pdt_feature_mobile01_tw.png';  //eslint-disable-line no-unused-vars
import feature2 from '../assets/img/readerMobile/pdt_feature_mobile02.png';  //eslint-disable-line no-unused-vars
import {getFeatureList} from '../api';
import {useDispatch} from 'react-redux';
import MetaTags from 'react-meta-tags';

const ReaderMobile = props => {
    const { lang } = useParams();
    const {t,currentLang,linkObj, i18n} = props;
    const dispatch = useDispatch();
    const [featureAry, setFeatureAry] = useState([]);
    const featureTypeId = 'aa305111-b83a-47d2-b1f5-9a849e3f500f';
    const renderQRcodelist = () => {
        let qrcodeAry = [
            {
                name:'iPhone',
                img:'iphone_'+currentLang.type,
                link:linkObj.downloadLink.ios.url('iphone'),
                linktype:'ios'
            },
            {
                name:'Android',
                img:currentLang.type === 'zh-CN'?'android_cn':'android',
                link:linkObj.downloadLink.android.url(),
                linktype:currentLang.type === 'zh-CN'?'wando':'android'
            }
        ]
        return(
            <ul className={'QRcodeList '+(currentLang.type !== 'zh-CN' && currentLang.type !== 'zh-TW'?'small':'')}>
                {
                    qrcodeAry.map((e,i)=>{
                        return (
                            <li key={i}>
                                <img src={require(`../assets/img/qrcode/${e.img}.png`)} className="img-fluid" alt="" />
                                <a href={e.link} target="_blank" rel="noopener noreferrer" className={'downloadBtn btn text-white '+e.linktype}> </a>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }
    
    useEffect(() => {
        const fetchResource = cLang => {
            let lang = 'EN';
            if(cLang === 'zh-TW'){
                lang = 'CT';
            }else if(cLang === 'zh-CN'){
                lang = 'CS';
            }else{
                lang = cLang.toUpperCase();
            }
            getFeatureList({LgeID:lang, TypeID:featureTypeId, PageIndex:0, PageSize:100}).then(function (response) {
                dispatch({
                    type:'SEND_FEATURELIST',
                    featureListAry:response.data.List
                })
                setFeatureAry(response.data.List);
                // renderSuiteList(response.data.List);
            })
            .catch(function (error) {
                console.log('err',error);
            });
        
        }

        fetchResource(currentLang.type);
    }, [currentLang.type,dispatch]);

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);

    const renderIsTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "1"){
		return data.IsTop === "1" ? 
            //if((i % 2)==0){
			((i % 2)===0 ? 
                (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            <h3 className="font-weight-bold b-text mb-3 title_style">{data.Name}</h3>
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {data.IsMore===1?<Link to={`/${props.currentLang.type}/app/readmore/${data.ID}`} className="mt-20 b1Btn">
                                {t('learnMore')}
                            </Link>:''}
                        </div>
                    </div>
                ) :
            //} else {
                (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-33 featureInfo alignRight align-middle">
                            <h3 className="font-weight-bold b-text mb-3 title_style">{data.Name}</h3>
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {data.IsMore===1?<Link to={`/${props.currentLang.type}/app/readmore/${data.ID}`} className="mt-20 b1Btn">
                                {t('learnMore')}
                            </Link>:''}
                        </div>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                    </div>
                ))
            //}
		: null;
        //}
    });
    
    const renderIsNotTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "0"){
            return data.IsTop === "0" ?(
                <div className="d-50 mb-120" key={i} >
                    <img src={data.Img_pc} className="img-fluid isNotTop-img" alt="" />
                    <div className="featureInfo paddL35 align-middle">
                        <h3 className="font-weight-bold m-text title_style">{data.Name}</h3>
                        <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                        {data.IsMore===1?<Link to={`/${props.currentLang.type}/app/readmore/${data.ID}`} className="mt-20 b1Btn">
                            {t('learnMore')}
                        </Link>:''}
                    </div>
                </div>
                
            ) : null;
        //}
    });

    return(
        <section id="readerMobile" className="productInfoContent">
            <MetaTags>
                <title>{t('metaTag.readerApp.title')}</title>
                <meta name="title" content={t('metaTag.readerApp.title')} />
                <meta name="description" content={t('metaTag.readerApp.desc')} />
                <meta name="keywords" content={t('metaTag.readerApp.keywords')} />
                <meta property="og:title" content={t('metaTag.readerApp.title')} />
                <meta property="og:description" content={t('metaTag.readerApp.desc')} />
            </MetaTags>
            <div className="topBlock contentBlock">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                             <h1 className="firstTitle title_style">{t('readerMobile.name')}</h1>
                              <p className={"secondTitle title_style "+((currentLang.type !== 'zh-TW' && currentLang.type !== 'zh-CN')&&"smallText")}>{t('readerMobile.info')}</p>
                              <div className="QRcodeListContent">
                                <p className="font-weight-bold">
                                    {t('header.download')}
                                </p>
                                {renderQRcodelist()}
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="featureContent contentBlock whiteBg">
                <div className="customContainer">
                    <div className="cRow maxW1300 padding-0">
                        <div className="d-100">
                            <h2 className="mb-title font-weight-bold mb-4 title_style">{t('productPage.featuresTitle')}</h2>
                            <p className="m-title m-0">{t('readerMobile.featuresInfo.t1')}</p>
                            <p className="m-title m-0">{t('readerMobile.featuresInfo.t2')}</p>
                            <span className="d-inline-block" style={{color:'#DC3C00',letterSpacing:'0.48px',marginTop:'64px'}}>{t('readerMobile.featuresInfo.note')}</span>
                        </div>
                    </div>
                    {/* <div className="cRow maxW1300 mtb-120 padding-0">
                        <div className="d-40 featureInfo max600 alignRight">
                            <img src={feature1}  className="img-fluid" alt="feature_01"/>
                        </div>
                        <div className="d-50 featureInfo max600 alignLeft align-middle">
                            <h3 className="font-weight-bold b-text mb-3">開啟並瀏覽PDF</h3>
                            <p>以優異的速度在您的行動裝置上開啟PDF，然後檢視文件夾、密碼保護的文件，甚至是搜尋關鍵字、跳到指定頁面、統一查看註解等──強大的Gaaiho PDF Reader都支援。</p>
                            <ul className="featureInfoList">
                                <li>
                                    自訂檢視模式
                                </li>
                                <li>
                                    輕鬆瀏覽文件
                                </li>
                                <li>
                                    管理並分享檔案
                                </li>
                                <li>
                                    貼心小設定
                                </li>
                            </ul>
                            <br/>
                            <a href="#" className="mt-20">
                                {t('learnMore')}
                            </a>
                        </div>
                    </div>
                    <div className="cRow maxW1300 mtb-120 padding-0 column-reverse">
                        <div className="d-50 featureInfo max600 alignRight">
                            <h3 className="font-weight-bold b-text mb-3">隨時隨地處理PDF文件</h3>
                            <p>即使出門在外，處理PDF文件依然便利。您可以為文件加上註解並利用面板全盤管理PDF中所有的註解。</p>
                            <ul className="featureInfoList">
                                <li>
                                    為PDF加上註解
                                </li>
                                <li>
                                    註解面板
                                </li>
                            </ul>
                            <br/>
                            <a href="#" className="mt-20">
                                {t('learnMore')}
                            </a>
                        </div>
                        <div className="d-40 featureInfo alignLeft align-middle">
                            <img src={feature2}  className="img-fluid" alt="feature_02"/>
                        </div>
                    </div> */}
                    {renderIsTopFeatureView}
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        {renderIsNotTopFeatureView}
                    </div>
                    {/* feature detail */}
                    <div className="cRow maxW1300 grayBg-gallery">
                        <div className="d-100  featureDetailInfo">
                            <h2 className="d-inline-block align-top" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.systemRequired.mainTitle')}
                            </h2>
                            <ul className="featureDetailList align-top">
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title4')}</p>
                                    <p>English、繁體中文、简体中文、日本語、한국어</p>
                                </li>
                                <li>
                                    <p className="font-weight-bold">{t('productPage.systemRequired.title5')}</p>
                                    <p>{t('readerMobile.systemRequired.info1')}</p>
                                    <p>{t('readerMobile.systemRequired.info2')}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature bottom */}
            <div className="bottomBlock contentBlock text-white">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                            <p className="secondTitle">{t('readerMobile.bottomInfo')}</p>
                            <div className="firstTitle">{t('readerMobile.name').split('(')[0]}</div>
                            <span className="d-inline-block" style={{margin:"60px auto",maxWidth:'320px',width:"100%",borderBottom:'1px solid #7B8488'}}></span>
                            {renderQRcodelist()}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default mixinWrapper(ReaderMobile);