import React, {useEffect, useState} from 'react';
import mixinWrapper from '../hoc/mixinWrapper';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeatureTable  from '../components/product/FeatureTable';
import {getFeatureList} from '../api';
import {useDispatch} from 'react-redux';
import MetaTags from 'react-meta-tags';


const Suite = props => {
    const { lang } = useParams();
    const {t,currentLang,linkObj, i18n} = props;
    const dispatch = useDispatch();
    const [featureAry, setFeatureAry] = useState([]);
    const featureTypeId = 'bb376294-23b1-4fc7-898f-6465d1a6fb35';
    const [activeType,setActiveType] = useState('win');
    /*const renderFeatureImg = (imgNum) =>{
        let returnElement = <img src={require(`../assets/img/suite/funIcon${imgNum}.png`)} className="img-fluid" alt={`${imgNum}`} />;
        return returnElement;
    }*/
    const authorizeSelect = (type) => {
        setActiveType(type);
    }
    const renderpdf = () => {
        let returnElement = null;

        returnElement = currentLang.type === 'zh-TW'?  <li><a href="https://www.pdfhome.com/zh-TW/home" target="_blank" rel="noopener noreferrer"><span className="font-light">PDFhome 教學網</span></a></li>:null;
        return returnElement;
    }
    const renderPrice = type => {
        let returnPrice = 0;
        if(currentLang.type === 'zh-TW'){
            if(type === 'sub'){
                returnPrice = '3,900';
            }else if(type === 'sub2'){
                returnPrice = 390;
            }else{
                returnPrice = '5,800';
            }
            
        }else if(currentLang.type === 'zh-CN'){
            if(type === 'sub'){
                returnPrice = 168;
            }else if(type === 'sub2'){
                returnPrice = 50;
            }else{
                returnPrice = 1250;
            }
        }else{
            if(type === 'sub'){
                returnPrice = 130;
            }else if(type === 'sub2'){
                returnPrice = 13;
            }else{
                returnPrice = 179;
            }
        }
        return returnPrice;
    }
    const renderPriceByMounth = () => {
        if(currentLang.type === 'zh-CN'){
            return '';
        }else{
            return <p className="m-0">( {t('productPage.or')}{t('productPage.money')} {renderPrice('sub2')}/{t('productPage.month')} )</p>
        }

    }
    const renderBusinessInfo = () => {
        if(currentLang.type === 'jp' || currentLang.type === 'kr'){
            return <><p className="mt-34">{t('productPage.business.info1')}</p> <p className="mb-34"><Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`}><b>{t('newfeature.upgrade.block2.title')}</b></Link> {t('productPage.business.info2')}</p></>
        }else{
            return <><p className="mt-34">{t('productPage.business.info1')}</p> <p className="mb-34">{t('productPage.business.info2')}<Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`}><b>{t('newfeature.upgrade.block2.title')}</b></Link></p></>
            
        }
    }
    //授權選項判斷
    const renderAuthorizeBlock2 = () => {
        if(currentLang.type !== 'zh-CN'){
            return <li><div className='authorizeBlock'><div className='blockTitle grayBg-corduroy'>{t('productPage.subscription.title')}</div><div className='prizeInfo macappstore borderLine dash'><p className='m-0' dangerouslySetInnerHTML={{__html: t('productPage.subscription.info').replace("$1$",`<br/>`)}}></p></div><div className='btnGroup macappstore'><a href={linkObj.macdownloadLink.url()} target='_blank' rel='noopener noreferrer' className='cBtn macAppStore'><img alt='' src={require(`../assets/img/mac.png`)}/></a></div></div></li>
        }
    }

    useEffect(() => {
        const fetchResource = cLang => {
            let lang = 'EN';
            if(cLang === 'zh-TW'){
                lang = 'CT';
            }else if(cLang === 'zh-CN'){
                lang = 'CS';
            }else{
                lang = cLang.toUpperCase();
            }
            getFeatureList({LgeID:lang, TypeID:featureTypeId, PageIndex:0, PageSize:100}).then(function (response) {
                dispatch({
                    type:'SEND_FEATURELIST',
                    featureListAry:response.data.List
                })
                setFeatureAry(response.data.List);
                // renderSuiteList(response.data.List);
            })
            .catch(function (error) {
                console.log('err',error);
            });
        
        }

        fetchResource(currentLang.type);
    }, [currentLang.type,dispatch]);

    useEffect(() => {
        if (currentLang.type !== lang) {
			const changeLang = value => {
				i18n.changeLanguage(value);
			}
            changeLang(lang);
        }
    }, [lang,currentLang.type,i18n]);

    const renderIsTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "1"){
		return data.IsTop === "1" ? 
            //if((i % 2)===0){
			((i % 2)===0 ? 
                (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                        <div className="d-33 featureInfo alignLeft align-middle">
                            <h3 className="font-weight-bold b-text mb-3 title_style">{data.Name}</h3>
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {data.IsMore===1?<Link to={`/${props.currentLang.type}/suite/readmore/${data.ID}`} className="mt-20 b1Btn">
                            {/* <Link to={`/${props.currentLang.type}/Product/Readmore/${data.ID}`} className="mt-20 b1Btn"> */}
                                {t('learnMore')}
                            </Link>:''}
                        </div>
                    </div>
    
                ) : 
            //} else {
                (
                    <div className="cRow maxW1300 mtb-120 padding-0" key={data.ID}>
                        <div className="d-33 featureInfo alignRight align-middle">
                            <h3 className="font-weight-bold b-text mb-3">{data.Name}</h3>
                            <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                            {data.IsMore===1?<Link to={`/${props.currentLang.type}/suite/readmore/${data.ID}`} className="mt-20 b1Btn">
                                {t('learnMore')}
                            </Link>:''}
                        </div>
                        <div className="d-65 featureImg align-middle maxd819">
                            <img src={data.Img_pc}  className="img-fluid" alt="feature_01"/>
                        </div>
                    </div>
    
                ))
            //}
		: null;
        //}
        
    });

    const renderIsNotTopFeatureView = featureAry.map((data,i)=>{
        //if(data.IsTop === "0"){
            return data.IsTop === "0" ? (
                <div className="d-50 mb-120" key={i}>
                    <img src={data.Img_pc} className="img-fluid isNotTop-img" alt={`${i}`} />
                    <div className="featureInfo paddL35 align-middle">
                        <h3 className="font-weight-bold m-text title_style">{data.Name}</h3>
                        <p dangerouslySetInnerHTML={{__html:data.Desc}}></p>
                        {data.IsMore===1?<Link to={`/${props.currentLang.type}/suite/readmore/${data.ID}`} className="mt-20 b1Btn">
                            {t('learnMore')}
                        </Link>:''}
                    </div>
                </div>
                
            ) : null;
        //}
        
    });
    
    return (
        <section id="suite" className="productInfoContent">
            <MetaTags>
                <title>{t('metaTag.suite.title')}</title>
                <meta name="title" content={t('metaTag.suite.title')} />
                <meta name="description" content={t('metaTag.suite.desc')} />
                <meta name="keywords" content={t('metaTag.suite.keywords')} />
                <meta property="og:title" content={t('metaTag.suite.title')} />
                <meta property="og:description" content={t('metaTag.suite.desc')} />
            </MetaTags>
            <div className="topBlock contentBlock text-white">
                <div className="customContainer">
                    <div className="cRow maxW1300">
                        <div className="d-100">
                            <h1 className="firstTitle title_style">{t('suite.name')}</h1>
                            <p className="secondTitle title_style">{t('suite.info')}</p>
                            <div className="btnGroup">
                            <a href={linkObj.downloadLink.suite.url()} className="cBtn w_text greenBg text-center" target="_blank" rel="noopener noreferrer">
                                {t('downloadNow')}
                                <p className="m-0 font-light">{t('freeTrial')}</p>
                            </a>
                            </div>
                            <div className="btnTips">
                              <span dangerouslySetInnerHTML={{__html: t('btnTips',{wIcon:'<i class="wIcon"></i>',mIcon:'<i class="mIcon"></i>'})}}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentBlock whiteBg">
                <div className="customContainer">
                    <div className="cRow maxW1300 grayBg-gallery">
                        <div className="d-100 authorizeContent">
                            <h2 className="mb-title font-weight-bold title_style">{t('productPage.licensingOption')}</h2>
                            <div className="authorizeSelect">
                              <div className={activeType==='win'?'active':''} onClick={authorizeSelect.bind(this,'win')}><i className="wIcon"></i><span>{t('suite.windowsversion')}</span></div>
                              <div className={activeType==='mac'?'active':''} onClick={authorizeSelect.bind(this,'mac')}><i className="mIcon"></i><span>{t('suite.macversion')}</span></div>
                            </div>
                            <div className="windowView" style={{'display':activeType==='win'?'block':'none'}}>
                                <ul className="authorizeList">
                                    <li className="active">
                                        <div className="authorizeBlock">
                                            <div className="blockTitle grayBg-corduroy">
                                                {t('productPage.subscription.title')}
                                            </div>
                                            <div className="prizeInfo borderLine dash bottom">
                                                <div className="font-light seth2">
                                                    <span>{t('productPage.money')}</span>{renderPrice('sub')}<span>/{t('productPage.year')}</span>
                                                </div>
                                                {renderPriceByMounth()}
                                            </div>
                                            <div className="btnGroup">
                                                <a href={linkObj.buyLink.url('#suite_sub')} target="_blank" rel="noopener noreferrer" className="cBtn w_text redBg-fire text-center">{t('header.buy')}</a>
                                            </div>
                                        </div>
                                        <Link to={`/${props.currentLang.type}${linkObj.resources.subscriptionCompare.path}`} className="questionLink">
                                            <span>?</span> {t('productPage.subscription.question')}
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="authorizeBlock">
                                            <div className="blockTitle grayBg-corduroy">
                                                {t('productPage.perpetual.title')}
                                            </div>
                                            <div className="prizeInfo borderLine dash bottom">
                                                <div className="font-light seth2">
                                                    <span>{t('productPage.money')} </span> {renderPrice('Perpetual')}<span>/{t('productPage.singleLicence')}</span>
                                                </div>
                                                <p className="m-0">( {t('productPage.perpetual.info')} )</p>
                                            </div>
                                            <div className="btnGroup">
                                                <a href={linkObj.buyLink.url('#suite_per')} target="_blank" rel="noopener noreferrer" className="cBtn w_text redBg-fire text-center">{t('header.buy')}</a>
                                                <a href={linkObj.upgradeLink.url()} target="_blank" rel="noopener noreferrer" className="cBtn red_border text-center">{t('oldUpgrade')}</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="authorizeBlock">
                                            <div className={(currentLang.type === 'zh-TW' ||  currentLang.type === 'zh-CN')?'blockTitle grayBg-corduroy':'blockTitle smText grayBg-corduroy'}>
                                                {t('productPage.TSL.title')}
                                            </div>
                                            <div className="prizeInfo borderLine dash bottom">
                                                <p className="m-0">{t('productPage.TSL.info')}</p>
                                            </div>
                                            <div className="btnGroup paddingBottom5">
                                                <a href={linkObj.buyLink.url('#suite_ts')}  target="_blank" rel="noopener noreferrer" className="cBtn w_text redBg-fire text-center">{t('header.buy')}</a>
                                            </div>
                                            <div className='updateContact'>
                                                <span dangerouslySetInnerHTML={{__html: t('oldUpgradeContact',{aTagStart:'<a href="/'+currentLang.type+'/contactUs" class="contactClick" target="_blank">',aTagEnd:'</a>'})}}></span>
                                            </div>
                                        </div>
                                        <Link to={`/${props.currentLang.type}${linkObj.resources.tslCompare.path}`} className="questionLink">
                                            <span>?</span>{t('productPage.TSL.question')}
                                        </Link>
                                    </li>
                                </ul>
                                <div className="authorizeBlock authorizeInfo">
                                    <div className="title_style seth2">{t('productPage.business.title')}</div>
                                    {renderBusinessInfo()}
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`} className="cBtn w_text greenBg text-center">
                                        {t('learnMore')}
                                    </Link>
                                </div>
                            </div>
                            <div className="macOSView" style={{'display':activeType==='mac'?'block':'none'}}>
                                <ul className="authorizeList" style={{justifyContent:'center'}}>
                                    <li>
                                        <div className="authorizeBlock">
                                            <div className="blockTitle grayBg-corduroy">
                                                {t('productPage.perpetual.title')}
                                            </div>
                                            <div className="prizeInfo borderLine dash bottom">
                                                <div className="font-light seth2">
                                                    <span>{t('productPage.money')} </span> {renderPrice('Perpetual')}<span>/{t('productPage.singleLicence')}</span>
                                                </div>
                                                <p className="m-0">( {t('productPage.perpetual.info')} )</p>
                                            </div>
                                            <div className="btnGroup">
                                                <a href={linkObj.buyLink.url('#mac_per')} target="_blank" rel="noopener noreferrer" className="cBtn w_text redBg-fire text-center">{t('header.buy')}</a>
                                            </div>
                                        </div>
                                    </li>
                                    {renderAuthorizeBlock2()}
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        <div className="d-100">
                            <h2 className="mb-title font-weight-bold mb-4 title_style">{t('productPage.featuresTitle')}</h2>
                            <p className="m-title m-0">{t('suite.featuresInfo.t1')}</p>
                            <p className="m-title m-0">{t('suite.featuresInfo.t2')}</p>
                        </div>
                    </div>
                    {renderIsTopFeatureView}
                    {/* feature list */}
                    <div className="cRow maxW1300 mtb-120 padding-0">
                        {renderIsNotTopFeatureView}
                    </div>
                    {/* feature detail */}
                    <div className="cRow maxW1300 mtb-120 grayBg-gallery">
                        {/* <div className="d-100 featureDetailInfo">
                            <h2 className="d-inline-block align-top" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.noteTitle')}
                            </h2>
                            <p className="d-inline-block align-top font-weight-light" >
                                {t('suite.note').split('_')[0]}

                                <Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`}  className="font-weight-normal">
                                    {t('suite.note').split('_')[1]}
                                </Link>

                                {t('suite.note').split('_')[2]}
                            </p>
                        </div> */}
                        <div className="d-100 featureDetailInfo">
                            <h2 className="d-inline-block align-top" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.systemRequired.mainTitle')}
                            </h2>
                            <ul className="featureDetailTwo">
                                <li>
                                    <div><i className="wIcon"></i><span>{t('suite.windowsversion')}</span></div>
                                    <div>
                                        <ul className="featureDetailList align-top">
                                            <li>
                                                <p className="font-weight-bold">{t('productPage.systemRequired.title1')}</p>
                                                <p>{t('suite.systemRequired.info1')}</p>
                                            </li>
                                            <li>
                                                <p className="font-weight-bold">{t('productPage.systemRequired.title2')}</p>
                                                <p>{t('suite.systemRequired.info2')}</p>
                                                <p>{t('suite.systemRequired.info3')}</p>
                                                <p>{t('suite.systemRequired.info4')}</p>
                                            </li>
                                            <li>
                                                <p className="font-weight-bold">{t('productPage.systemRequired.title3')}</p>
                                                <p>{t('suite.systemRequired.info5')}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div><i className="mIcon"></i><span>{t('suite.macversion')}</span></div>
                                    <div>
                                        <ul className="featureDetailList align-top">
                                            <li>
                                                <p className="font-weight-bold">{t('productPage.systemRequired.title1')}</p>
                                                <p>{t('mac.systemRequired.info1')}</p>
                                            </li>
                                            <li>
                                                <p className="font-weight-bold">{t('productPage.systemRequired.title2')}</p>
                                                <p>{t('mac.systemRequired.info2')}</p>
                                                <p>{t('mac.systemRequired.info3')}</p>
                                                <p>{t('mac.systemRequired.info4')}</p>
                                            </li>
                                            <li>
                                                <p className="font-weight-bold">{t('productPage.systemRequired.title3')}</p>
                                                <p>{t('mac.systemRequired.info5')}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            
                        </div>
                        <div className="d-100 featureDetailInfo">
                            <h2 className="d-inline-block" style={{minWidth:'99px',marginRight:'70px',marginBottom:'25px'}}>
                                {t('productPage.resourceTitle')}
                            </h2>
                            <ul className="featureDetailList boxList align-top">
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.tutorials.userManuals.path}`}>
                                        <span className="font-light">{t('tutorials.userManuals')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.compare.path}`}>
                                        <span className="font-light">
                                            {t('resources.link1').split("/")[0]} /<br/>
                                            {t('resources.link1').split("/")[1]}
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.documents.path}`}>
                                         <span className="font-light">
                                             {t('resources.link2').split("/")[0]} /<br/>
                                             {t('resources.link2').split("/")[1]}
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.currentLang.type}${linkObj.resources.release.path}`}>
                                        <span className="font-light">{t('resources.link6')}</span>
                                    </Link>
                                </li>
                                {
                                    renderpdf()
                                }
                            </ul>
                        </div>
                    </div>
                    {/* feature table */}
                    <div className="cRow maxW1300">
                        <div className="d-100">
                            <h2 className="mb-title font-weight-bold mb-120">{t('suite.name')}{t('productPage.compareTitle')}</h2>
                            <FeatureTable t={t} linkObj={linkObj} highLightIndex={3} currentLang={currentLang} />
                            <p  className="font-light" style={{color:'black',marginBottom:'50px'}}>
                                {t('home.compare.remote.info')}
                                <Link to={`/${props.currentLang.type}${linkObj.resources.remoteEnterprise.path}`} style={{color:'black',fontWeight:'400'}}>
                                    {t('home.compare.remote.link')}
                                </Link>    
                                {t('home.compare.remote.link2')}
                            </p>
                            <Link to={`/${props.currentLang.type}${linkObj.resources.compare.path}`} className="cBtn b_border">
                                {t('home.compare.more')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
             {/* feature bottom */}
             <div className="bottomBlock contentBlock text-white">
                    <div className="customContainer">
                        <div className="cRow maxW1300">
                            <div className="d-100"> 
                                <p className="secondTitle">{t('suite.bottomInfo')}</p>
                                <p className="firstTitle">{t('suite.name')}</p>
                                <div className="btnGroup">
                                    <a href={linkObj.downloadLink.suite.url()} className="cBtn w_text greenBg text-center boxShadow" target="_blank" rel="noopener noreferrer">
                                        {t('downloadNow')}
                                        <p className="m-0 font-light">{t('freeTrial')}</p>
                                    </a>
                                </div>
                                <p>
                                    {t('suite.uploadInfo')} 
                                    <FontAwesomeIcon  icon="chevron-right" className="fa-sm mr-2 ml-2"/>
                                    <a href={linkObj.header.upgrade.url()} className="d-inline-block text-white" style={{textDecoration:'underline'}} target="_blank" rel="noopener noreferrer">
                                        {t(linkObj.header.upgrade.name)}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>            
             </div>
        </section>
    )
}

export default mixinWrapper(Suite);